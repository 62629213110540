import React, { useState, useEffect } from "react";
import style from "./VehicleManagement.module.css";
import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  TextField,
  Snackbar,
} from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseUrl } from "../Url";
import { useOutletContext } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import Loader from "../Loader/Loader";

const VehicleManagement = () => {
  const { selectedNewHub } = useOutletContext();

  const token = JSON.parse(localStorage.getItem("access-token"));

  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleDesc, setVehicleDesc] = useState("");
  const [status, setStatus] = useState("");
  const [isTransportable, setIsTransportable] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [createSuccess, setCreateSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [vehicleID, setVehicleID] = useState("");
  const [editVehicleName, setEditVehicleName] = useState("");
  const [editVehicleDesc, setEditVehicleDesc] = useState("");
  const [editVehicleRegistration, setEditVehicleRegistration] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [editIsTransportable, setEditIsTransportable] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validateFields = () => {
    if (!vehicleName) {
      setErrorMessage("Vehicle Name is required");
      return false;
    }
    if (!vehicleDesc) {
      setErrorMessage("Description is required");
      return false;
    }
    if (!vehicleType) {
      setErrorMessage("Vehicle Type is required");
      return false;
    }
    if (!regNumber) {
      setErrorMessage("Registration number is required");
      return false;
    }
    if (isTransportable === "") {
      setErrorMessage("Transportable status is required");
      return false;
    }
    if (!status) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  };

  const validateEditFields = () => {
    if (!editVehicleName) {
      setErrorMessage("Vehicle Name is required");
      return false;
    }
    if (!editVehicleDesc) {
      setErrorMessage("Description is required");
      return false;
    }
    if (!editVehicleRegistration) {
      setErrorMessage("Registration is required");
      return false;
    }
    if (editIsTransportable === "") {
      setErrorMessage("Transportable status is required");
      return false;
    }
    if (!editStatus) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  };

  async function vehicleList() {
    const reqData = {
      hub_id: selectedNewHub,
    };
    setLoading(true);
    await fetch(`${baseUrl}/list/vehicle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setVehicleData(data.data);
        }
      })
      .finally(() => setLoading(false)); 
  }

  async function vehicleCreate() {
    if (!validateFields()) {
      setValidationFail(true);
      return;
    }
    const reqData = {
      hub_id: selectedNewHub,
      vehicle_name: vehicleName,
      description: vehicleDesc,
      is_transportable: isTransportable === "true" ? true : false,
      status: status,
      registration_number: regNumber,
      vehicle_type: vehicleType,
    };
    await fetch(`${baseUrl}/add/vehicle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setCreatePopup(false);
          setCreateSuccess(true);
          vehicleList();
          setVehicleName("");
          setVehicleDesc("");
          setRegNumber("");
          setVehicleType("");
          setIsTransportable("");
          setStatus("");
        } else {
          setValidationFail(true);
          setErrorMessage(data.description);
        }
      });
  }

  async function vehicleUpdate() {
    if (!validateEditFields()) {
      setValidationFail(true);
      return;
    }
    const reqData = {
      vehicle_id: vehicleID,
      hub_id: selectedNewHub,
      vehicle_name: editVehicleName,
      description: editVehicleDesc,
      registration_number: editVehicleRegistration,
      is_transportable: editIsTransportable === "true" ? true : false,
      status: editStatus,
    };
    await fetch(`${baseUrl}/update/vehicle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setEditPopup(false);
          setEditSuccess(true);
          vehicleList();
          setEditVehicleName("");
          setEditVehicleDesc("");
          setEditVehicleRegistration("");
          setEditIsTransportable("");
          setEditStatus("");
        } else {
          setValidationFail(true);
          setErrorMessage(data.description);
        }
      });
  }

  async function vehicleDelete() {
    const reqData = {
      vehicle_id: vehicleID,
    };
    await fetch(`${baseUrl}/delete/vehicle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setDeletePopup(false);
          setDeleteSuccess(true);
          setVehicleID("");
          vehicleList();
        }
      });
  }

  useEffect(() => {
    if (selectedNewHub) {
      vehicleList();
    }
  }, [selectedNewHub]);
  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={createSuccess || deleteSuccess || editSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCreateSuccess(false);
          setDeleteSuccess(false);
          setEditSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {createSuccess
            ? "Vehicle created successfully"
            : editSuccess
            ? "Vehicle Updated successfully"
            : deleteSuccess
            ? "Vehicle deleted successfully"
            : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Vehicles</p>
        </div>
        <div className={style.headerActions}>
          <button
            onClick={() => {
              setCreatePopup(true);
            }}
          >
            Add Vehicle
          </button>
        </div>
      </div>
      {loading ? (
              <Loader />
            ) : (
      <div className={style.body}>
        <TableContainer className={style.table} component={Paper}>
          <Table aria-label="caption table">
            <TableHead className={style.tableHead}>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Registration Number
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Vehicle Code
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Vehicle Type
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Transportable
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicleData && vehicleData.length > 0 ? (
                vehicleData.map((data, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="left">
                        {data.registration_number}
                      </TableCell>
                      <TableCell align="left">{data.vehicle_name}</TableCell>
                      <TableCell align="left">{data.vehicle_type}</TableCell>
                      <TableCell align="left">{data.description}</TableCell>
                      <TableCell align="left">
                        {data.is_transportable ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="left">{data.status}</TableCell>

                      <TableCell align="left">
                        <BiEditAlt
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          title="Edit"
                          onClick={() => {
                            setEditPopup(true);
                            setVehicleID(data.vehicle_id);
                            setEditVehicleDesc(data.description);
                            setEditVehicleName(data.vehicle_name);
                            setEditVehicleRegistration(
                              data.registration_number
                            );
                            setEditIsTransportable(
                              data.is_transportable === true ? "true" : "false"
                            );
                            setEditStatus(data.status);
                          }}
                        />
                        <RiDeleteBinLine
                          style={{
                            fontSize: "20px",
                            marginLeft: "15px",
                            cursor: "pointer",
                          }}
                          title="Delete"
                          onClick={() => {
                            setDeletePopup(true);
                            setVehicleID(data.vehicle_id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
       )}
      {createPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Create Vehicle</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setCreatePopup(false);
                    setVehicleName("");
                    setVehicleDesc("");
                    setIsTransportable("");
                    setRegNumber("");
                    setVehicleType("");
                    setStatus("");
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={vehicleName}
                onChange={(e) => {
                  setVehicleName(e.target.value);
                }}
                label="Vehicle Code"
                fullWidth
                margin="normal"
              />

              <TextField
                value={vehicleDesc}
                onChange={(e) => {
                  setVehicleDesc(e.target.value);
                }}
                label="Description"
                fullWidth
                margin="normal"
              />
              <TextField
                value={regNumber}
                onChange={(e) => {
                  setRegNumber(e.target.value);
                }}
                label="Registration Number"
                fullWidth
                margin="normal"
              />
              <TextField
                value={vehicleType}
                onChange={(e) => {
                  setVehicleType(e.target.value);
                }}
                label="Vehicle Type"
                fullWidth
                margin="normal"
              />
              <TextField
                select
                label="Select Transportable"
                fullWidth
                margin="normal"
                value={isTransportable}
                onChange={(e) => {
                  setIsTransportable(e.target.value);
                }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              <TextField
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => vehicleCreate()}
                  variant="contained"
                >
                  SUBMIT
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                    setVehicleName("");
                    setVehicleDesc("");
                    setRegNumber("");
                    setVehicleType("");
                    setIsTransportable("");
                    setStatus("");
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {editPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Update Vehicle</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setEditPopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={editVehicleName}
                onChange={(e) => {
                  setEditVehicleName(e.target.value);
                }}
                label="Vehicle Code"
                fullWidth
                margin="normal"
              />

              <TextField
                value={editVehicleDesc}
                onChange={(e) => {
                  setEditVehicleDesc(e.target.value);
                }}
                label="Description"
                fullWidth
                margin="normal"
              />

              <TextField
                value={editVehicleRegistration}
                onChange={(e) => {
                  setEditVehicleRegistration(e.target.value);
                }}
                label="Registration"
                fullWidth
                margin="normal"
              />

              <TextField
                select
                label="Select Transportable"
                fullWidth
                margin="normal"
                value={editIsTransportable}
                onChange={(e) => {
                  setEditIsTransportable(e.target.value);
                }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>

              <TextField
                value={editStatus}
                onChange={(e) => {
                  setEditStatus(e.target.value);
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => vehicleUpdate()}
                  variant="contained"
                >
                  SUBMIT
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setEditPopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {deletePopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.deletePopup}>
            <div className={style.deletePopupHeader}>
              <div className={style.deletePopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.deletePopupSection}>
              <div className={style.deletePopupText}>
                <p>Are you sure want to delete?</p>
              </div>
              <div className={style.deletePopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => vehicleDelete()}
                >
                  Delete
                </button>
                <button
                  className={style.closeButton}
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default VehicleManagement;
