import React, { useEffect, useState } from "react";
import style from "./InventoryDetails.module.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../Url";

function InventoryDetails() {
  const [assetData, setAssetData] = useState({});
  const [locationHistory, setLocationHistory] = useState([]);

  const location = useLocation();
  const asset_id = location.state?.asset_id;
  const asset_location = location.state?.asset_location;
  const token = JSON.parse(localStorage.getItem("access-token"));

  async function getInventoryAssetDetails() {
    await fetch(`${baseUrl}/inventory/asset/details/v1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        asset_id: asset_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setAssetData(data.data);
          setLocationHistory(data.data.asset_status);
        }
      });
  }

  useEffect(() => {
    if (asset_id) {
      getInventoryAssetDetails();
    }
  }, [asset_id]);
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Inventory Details</p>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.bodyLeft}>
          <div className={style.orderDetails}>
            <div className={style.orderDetailBody}>
              <div className={style.orderDetailCard}>
                <div className={style.orderDetailCardLeft}>
                  <div className={style.cardImage}>
                    <img
                      style={{ width: "80px", height: "80px" }}
                      src={
                        assetData.MediaThumbnail
                          ? assetData.MediaThumbnail
                          : "No Image"
                      }
                      alt="sample"
                    />
                  </div>
                </div>
                <div className={style.orderDetailCardRight}>
                  <div className={style.cardRightTop}>
                    <span>{assetData.ItemName}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.customerDetails}>
            <div className={style.customerDetailsHead}>
              <p>Asset Details</p>
            </div>
            <div className={style.customerDetailsBody}>
              <div className={style.customerDetailsBodyLeft}>
                <div className={style.customerDetailRow}>
                  <div className={style.customerDetailRowData}>
                    <p>Name</p>
                  </div>
                  <div className={style.customerDetailRowData}>
                    <span>{assetData?.ItemName || "N/A"}</span>
                  </div>
                </div>
                <div className={style.customerDetailRow}>
                  <div className={style.customerDetailRowData}>
                    <p>Asset Code</p>
                  </div>
                  <div className={style.customerDetailRowData}>
                    <span>{assetData?.InternalItemCode || "N/A"}</span>
                  </div>
                </div>
              </div>
              <div className={style.customerDetailsBodyRight}>
                <div className={style.customerDetailRow}>
                  <div className={style.customerDetailRowData}>
                    <p>Barcode</p>
                  </div>
                  <div className={style.customerDetailRowData}>
                    <span>{assetData?.bar_code || "N/A"}</span>
                  </div>
                </div>
                <div className={style.customerDetailRow}>
                  <div className={style.customerDetailRowData}>
                    <p>HSN Code</p>
                  </div>
                  <div className={style.customerDetailRowData}>
                    <span>{assetData?.hsn_code || "N/A"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.bodyRight}>
          <div className={style.paymentDetails}>
            <div className={style.staffDetailsHead}>
              <p>Location Details</p>
            </div>
            <div className={style.customerDetailRow}>
              <div className={style.customerDetailRowData}>
                <p>Current Location</p>
              </div>
              <div className={style.customerDetailRowData}>
                <span>{asset_location}</span>
                {/* <span>{locationHistory[locationHistory.length - 1]?.status || 'N/A'}</span> */}
              </div>
            </div>

            {asset_location === "Hub" && (
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Hub Name</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>{assetData.org_name || ""}</span>
                </div>
              </div>
            )}

            {asset_location === "Customer Side" && (
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Customer Name</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>{assetData.customer_name || ""}</span>
                </div>
              </div>
            )}

            {asset_location === "Staff Side" && (
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Staff Name</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>{assetData.staff_name || ""}</span>
                </div>
              </div>
            )}
            {/* <div className={style.locationHistory}>
              {locationHistory.length > 0 ? locationHistory.map((loc, index) => (
                <div
                  key={index}
                  className={`${style.locationRow} ${index === locationHistory.length - 1 ? style.currentLocation : ''}`}
                >
                  <div className={style.locationData}>
                    <p>{loc.status}</p>
                    <span>{new Date(loc.created_date).toLocaleDateString()}</span>
                  </div>
                </div>
              )) : <p>No location history available.</p>}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryDetails;
