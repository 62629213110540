import React, { useState, useEffect } from "react";
import style from "./ModuleListing.module.css";
import { baseUrl } from "../Url";
import Checkbox from "@mui/material/Checkbox";
import PermissionsContent from "../PermissionsContent/PermissionsContent ";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

function ModuleListing({ roleName }) {
  const [items, setItems] = useState([]);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [showAnimation, setShowAnimation] = useState(false);
  const [showPermissionContent, setShowPermissionContent] = useState(false);
  const [showTableContainer, setShowTableContainer] = useState(true);
  const [selectedModules, setSelectedModules] = useState([]);
  const togglePermissionContent = () => {
    setShowPermissionContent(!showPermissionContent);
    setShowTableContainer(false);
  };
  const roleCode = localStorage.getItem("role_code");

  async function fetchData() {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/rolewise/module/list`, {
        method: "POST",
        body: JSON.stringify({
          role_code:roleCode
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      });
      const data = await response.json();
      setItems(data.data);

          // Pre-select modules based on module_access
    const preSelectedModules = data.data
    .filter((item) => item.module_access) // Only include modules with access
    .map((item) => item.module_code); // Extract module codes
  setSelectedModules(preSelectedModules);
  localStorage.setItem("selectedModules", JSON.stringify(preSelectedModules));
    } catch (error) {
      console.error("Error fetching module data:", error);
    }
  }

  useEffect(() => {
    fetchData();
    setShowAnimation(true);
  }, [roleName]);

  const handleCheckboxChange = (moduleCode) => {
    const updatedSelectedModules = selectedModules.includes(moduleCode)
      ? selectedModules.filter((code) => code !== moduleCode)
      : [...selectedModules, moduleCode];
    setSelectedModules(updatedSelectedModules);
    localStorage.setItem(
      "selectedModules",
      JSON.stringify(updatedSelectedModules)
    );
  };

  useEffect(() => {
    const storedSelectedModules = JSON.parse(
      localStorage.getItem("selectedModules")
    );
    if (storedSelectedModules) {
      setSelectedModules(storedSelectedModules);
    }
  }, []);

  return (
    <>
      <div
        className={`${style.container} ${showAnimation ? style.slideIn : ""}`}
      >
        <div
          className={style.tableContainer}
          style={{ display: showTableContainer ? "block" : "none" }}
        >
          <div className={style.Header}>
            <div className={style.HeaderText}>
              <p>Module List for <span>: {roleName}</span></p>
            </div>
          </div>
          <TableContainer className={style.table} component={Paper}>
            <Table>
              <TableHead className={style.tableHead}>
                <TableRow>
                  <TableCell
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >Modules</TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >Permissions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.module_id}>
                    <TableCell>{item.module_name} </TableCell>

                    <TableCell align="center">
                      <Checkbox
                        {...label}
                        sx={{
                          color: "#59293a",
                          "&.Mui-checked": {
                            color: "#59293a",
                          },
                        }}
                        onChange={() => handleCheckboxChange(item.module_code)}
                        checked={selectedModules.includes(item.module_code)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={style.nextBtn}>
            {" "}
            <button className={style.next} onClick={togglePermissionContent}>
              Next
            </button>
          </div>
        </div>

        <div className={style.PermBox}>
          {showPermissionContent && <PermissionsContent />}
        </div>
      </div>
    </>
  );
}

export default ModuleListing;
