import {
  Alert,
  Box,
  FormControl,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./MenuItems.module.css";
import { IoSearch } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseUrl } from "../Url";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Loader from "../Loader/Loader";

function MenuItems() {
  const [createPopup, setCreatePopup] = useState("");
  const [menuItemsData, setMenuItemsData] = useState([]);
  const [createName, setCreateName] = useState("");
  const [createShortDesc, setCreateShortDesc] = useState("");
  const [createLongDesc, setCreateLongDesc] = useState("");
  const [createItemType, setCreateItemType] = useState("");
  const [createVarientExist, setCreateVarientExist] = useState("");
  const [createMakingCost, setCreateMakingCost] = useState("");
  const [createInvnCost, setCreateInvnCost] = useState("");
  const [createPurchasePrice, setCreatePurchasePrice] = useState("");
  const [createSalePrice, setCreateSalePrice] = useState("");
  const [createDiscountPercentage, setCreateDiscountPercentage] = useState("");
  const [createPurchasePercentage, setCreatePurchasePercentage] = useState("");
  const [createSaleTaxPercentage, setCreateSaleTaxPercentage] = useState("");
  const [createStatus, setCreateStatus] = useState("");
  const [updatePopup, setUpdatePopup] = useState("");
  const [editId, setEditId] = useState("");
  const [editName, setEditName] = useState("");
  const [editShortDesc, setEditShortDesc] = useState("");
  const [editLongDesc, setEditLongDesc] = useState("");
  const [editItemType, setEditItemType] = useState("");
  const [editVarient, setEditVarient] = useState("");
  const [editMakingCost, setEditMakingCost] = useState("");
  const [editInvenCost, setEditInvenCost] = useState("");
  const [editPurchasePrice, setEditPurchasePrice] = useState("");
  const [editSalePrice, setEditSalePrice] = useState("");
  const [editDiscPercentage, setEditDiscPercentage] = useState("");
  const [editPurchasePercentage, setEditPurchasePercentage] = useState("");
  const [editSalePercentage, setEditSalePercentage] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [deletePopup, setDeletePopup] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [deleteID, setDeleteID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [roleFail, setRoleFail] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [itemSuccessSnack, setItemSuccessSnack] = useState(false);
  const [itemDeletionSnack, setItemDeletionSnack] = useState(false);
  const [itemUpdationSnack, setItemUpdationSnack] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [popupActionType, setPopupActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("All");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgId = queryParams.get("orgId");
  const categoryId = queryParams.get("categoryId");
  console.log("orgid: ", orgId, "categoryId: ", categoryId);
  const token = JSON.parse(localStorage.getItem("access-token"));
  const navigate = useNavigate();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 200,
        },
      },
    };

  const createValidation = () => {
    if (!createName) {
      setErrorMessage("Item Name is required");
      return false;
    }
    if (!createShortDesc) {
      setErrorMessage("Short Description is required");
      return false;
    }
    if (!createLongDesc) {
      setErrorMessage("Long Description is required");
      return false;
    }
    if (!createItemType) {
      setErrorMessage("Item type is required");
      return false;
    }
    if (!createVarientExist) {
      setErrorMessage("Varient is required");
      return false;
    }
    if (!createMakingCost) {
      setErrorMessage("Making Cost is required");
      return false;
    }
    if (!createInvnCost) {
      setErrorMessage("Inventory Cost is required");
      return false;
    }
    if (!createPurchasePrice) {
      setErrorMessage("Purchase price is required");
      return false;
    }
    if (!createSalePrice) {
      setErrorMessage("Sale price is required");
      return false;
    }
    if (!createDiscountPercentage) {
      setErrorMessage("Discount percentage is required");
      return false;
    }
    if (!createPurchasePercentage) {
      setErrorMessage("Purchase percentage is required");
      return false;
    }
    if (!createSaleTaxPercentage) {
      setErrorMessage("Saletax percentage is required");
      return false;
    }
    if (!createStatus) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  };

  const updateValidation = () => {
    if (!editName) {
      setErrorMessage("Item Name is required");
      return false;
    }
    if (!editShortDesc) {
      setErrorMessage("Short Description is required");
      return false;
    }
    if (!editLongDesc) {
      setErrorMessage("Long Description is required");
      return false;
    }
    if (!editItemType) {
      setErrorMessage("Item type is required");
      return false;
    }
    if (!editVarient) {
      setErrorMessage("Varient is required");
      return false;
    }
    if (!editMakingCost) {
      setErrorMessage("Making Cost is required");
      return false;
    }
    if (!editInvenCost) {
      setErrorMessage("Inventory Cost is required");
      return false;
    }
    if (!editPurchasePrice) {
      setErrorMessage("Purchase price is required");
      return false;
    }
    if (!editSalePrice) {
      setErrorMessage("Sale price is required");
      return false;
    }
    if (!editDiscPercentage) {
      setErrorMessage("Discount percentage is required");
      return false;
    }
    if (!editPurchasePercentage) {
      setErrorMessage("Purchase percentage is required");
      return false;
    }
    if (!editSalePercentage) {
      setErrorMessage("Saletax percentage is required");
      return false;
    }
    if (!editStatus) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  };

  function openPopup(actionType, itemId, itemName) {
    setPopupActionType(actionType);
    setDeleteID(itemId);
    setDeleteName(itemName);
    setDeletePopup(true);
  }

  async function menuItemsList(categoryId) {
    if (categoryId === null || categoryId === undefined) {
      categoryId = "All";
    }
    setLoading(true);
    await fetch(`${baseUrl}/items/under/category`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        category_id: categoryId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "menuItemsListing");
        if (data.status === true) {
          setMenuItemsData(data.data);
        }
      })
      .finally(() => setLoading(false)); 
  }

  async function menuItemsCreate(orgId, categoryId) {
    const reqData = {
      org_id: orgId,
      category_id: categoryId,
      ItemName: createName,
      ShortDescription: createShortDesc,
      LongDescription: createLongDesc,
      ItemType: createItemType,
      IsVariantsExist: createVarientExist,
      MakingCost: createMakingCost,
      InventoryCost: createInvnCost,
      PurchasePrice: createPurchasePrice,
      SalePrice: createSalePrice,
      SaleDiscountPercentage: createDiscountPercentage,
      PurchaseTaxPercentage: createPurchasePercentage,
      SaleTaxPercentage: createSaleTaxPercentage,
      Status: createStatus,
    };
    if (!createValidation()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/Item/creation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "createdData");
          if (data.status === true) {
            setCreatePopup(false);
            menuItemsList(categoryId);
            setItemSuccessSnack(true);
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        });
    }
  }

  async function menuItemsUpdate() {
    const reqData = {
      itemID: editId,
      ItemName: editName,
      ShortDescription: editShortDesc,
      LongDescription: editLongDesc,
      ItemType: editItemType,
      IsVariantsExist: editVarient,
      MakingCost: editMakingCost,
      InventoryCost: editInvenCost,
      PurchasePrice: editPurchasePrice,
      SalePrice: editSalePrice,
      SaleDiscountPercentage: editDiscPercentage,
      PurchaseTaxPercentage: editPurchasePercentage,
      SaleTaxPercentage: editSalePercentage,
      Status: editStatus,
    };
    if (!updateValidation()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/Item/edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "updateData");
          if (data.status === true) {
            setUpdatePopup(false);
            menuItemsList(categoryId);
            setItemUpdationSnack(true);
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        });
    }
  }

  async function menuItemDelete() {
    await fetch(`${baseUrl}/item/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        itemID: deleteID,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "deleteData");
        if (data.status === true) {
          setDeletePopup(false);
          menuItemsList(categoryId);
          setItemDeletionSnack(true);
        } else {
          setApiDescription(data.description);
          setRoleFail(true);
        }
      });
  }

  async function menuItemUnmap() {
    await fetch(`${baseUrl}/category/item/unmap`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        item_id: deleteID,
        category_id: categoryId,
        hub_id: orgId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setDeletePopup(false);
          menuItemsList(categoryId);
        }
      });
  }

  async function menuItemSearch(categoryId, searchQuery, filterType) {
    const reqData = {
      category_id: categoryId,
      search: searchQuery,
      type:"stock",
      sort:filterType

    };
    await fetch(`${baseUrl}/items/search/v1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "searchData");
        if (data.status === true) {
          setMenuItemsData(data.data);
        } else {
          setMenuItemsData([]);
        }
      });
  }

  useEffect(() => {
    menuItemsList(categoryId);
  }, [categoryId]);
  useEffect(() => {
    menuItemSearch(categoryId, searchValue, filterType);
  }, [filterType]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={itemSuccessSnack || itemDeletionSnack || itemUpdationSnack}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setItemSuccessSnack(false);
          setItemDeletionSnack(false);
          setItemUpdationSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {itemSuccessSnack
            ? "Item created successfully"
            : itemDeletionSnack
            ? "Item deleted successfully"
            : itemUpdationSnack
            ? "Item updated successfully"
            : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={roleFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setRoleFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Stock Items</p>
        </div>
        <div>
          <Box sx={{ minWidth: 200 }}>
            <FormControl sx={{ mr: 1, minWidth: 160 }} size="small">
              <Select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
                displayEmpty
                input={
                  <OutlinedInput
                    sx={{
                      "& fieldset": {
                        borderColor: "#59293a",
                        borderWidth: 2,
                      },
                      "&fieldset:hover ": {
                        borderColor: "#794a57",
                      },
                      "& fieldset:active": {
                        borderColor: "#59293a",
                      },
                    }}
                  />
                }
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Stock">Stock</MenuItem>
                <MenuItem value="Asset">Asset</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className={style.search}>
          <IoSearch className={style.searchIcon} />
          <input
            type="text"
            placeholder="search"
            className={style.searchInput}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (e.target.value === "") {
                menuItemsList(categoryId,"",filterType);
              } else {
                menuItemSearch(categoryId, e.target.value, filterType);
              }
            }}
          />
        </div>
        <button
          onClick={() => {
            // setCreatePopup(true)
            if (categoryId === null || categoryId === undefined) {
              navigate("/itemcreation");
            } else {
              navigate(`/itemcreation?categoryId=${categoryId}`);
            }
          }}
          className={style.buttonSection}
        >
          Add Stock Items
        </button>
      </div>
      {loading ? (
              <Loader />
            ) : (
      <div className={style.tableSection}>
        <TableContainer component={Paper} className={style.table}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead className={style.tableHead}>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                    width: "10%",
                  }}
                >
                  Image
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                    width: "13%",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                    width: "10%",
                  }}
                >
                  Price
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                    width: "29%",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                    width: "15%",
                  }}
                >
                  Created Date
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                    width: "8%",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "center",
                    color: "#000",
                    width: "15%",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            {/* {loading ? (
              <Loader />
            ) : ( */}
            <TableBody>
              {menuItemsData &&
                menuItemsData.length > 0 &&
                menuItemsData.map((list) => {
                  return (
                    <TableRow key={list.itemID}>
                      <TableCell>
                        {list.media ? (
                          <img
                            style={{ width: "80px", height: "80px" }}
                            src={list.media[0]?.MediaThumbnail}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {list.ItemName}
                      </TableCell>
                      <TableCell align="left">{list.SalePrice}</TableCell>
                      <TableCell align="left">
                        {list.ShortDescription.length > 130
                          ? list.ShortDescription.substring(0, 115) + "..."
                          : list.ShortDescription}
                      </TableCell>
                      <TableCell align="left">
                        {(() => {
                          const date = new Date(list.UpdatedTimestamp.$date);
                          const day = String(date.getDate()).padStart(2, "0");
                          const month = String(date.getMonth() + 1).padStart(
                            2,
                            "0"
                          ); // Months are zero-based
                          const year = date.getFullYear();
                          return `${day}-${month}-${year}`;
                        })()}
                      </TableCell>
                      <TableCell align="center">
                        {list.Status === "Active" ? (
                          <FaRegCircleCheck
                            style={{ color: "green", fontSize: "1.35rem" }}
                          />
                        ) : list.Status === "Inactive" ? (
                          <IoIosCloseCircleOutline
                            style={{ color: "red", fontSize: "1.35rem" }}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {["All", null, undefined].includes(categoryId) && (
                          <BiEditAlt
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            title="Edit"
                            onClick={() => {
                              navigate("/item/update", {
                                state: {
                                  editId: list.itemID,
                                  editName: list.ItemName,
                                  editShortDesc: list.ShortDescription,
                                  editLongDesc: list.LongDescription,
                                  editItemType: list.ItemType,
                                  editVarient: list.IsVariantsExist,
                                  editMakingCost: list.MakingCost,
                                  editInvenCost: list.InventoryCost,
                                  editPurchasePrice: list.PurchasePrice,
                                  editSalePrice: list.SalePrice,
                                  editDiscPercentage:
                                    list.SaleDiscountPercentage,
                                  editPurchasePercentage:
                                    list.PurchaseTaxPercentage,
                                  editSalePercentage: list.SaleTaxPercentage,
                                  editStatus: list.Status,
                                },
                              });
                            }}
                          />
                        )}
                        <RiDeleteBinLine
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            marginLeft: "15px",
                          }}
                          title={
                            categoryId === "All" ||
                            categoryId === null ||
                            categoryId === undefined
                              ? "Delete"
                              : "Unmap"
                          }
                          onClick={() =>
                            openPopup(
                              categoryId === "All" ||
                                categoryId === null ||
                                categoryId === undefined
                                ? "delete"
                                : "unmap",
                              list.itemID,
                              list.ItemName
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
       )}

      {createPopup && (
        <Modal
          sx={{ transform: "tra" }}
          open={createPopup}
          onClose={() => {
            setCreatePopup(false);
          }}
          aria-labelledby="add-hub-modal"
          aria-describedby="add-hub-form"
        >
          <Paper className={style.createModal} elevation={3}>
            <h2 id="add-hub-modal">Add Stock Items</h2>
            <div className={style.modalContent}>
              <TextField
                label="Item Name"
                fullWidth
                margin="normal"
                value={createName}
                onChange={(e) => {
                  setCreateName(e.target.value);
                }}
              />
              <TextField
                label="Short Description"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                value={createShortDesc}
                onChange={(e) => {
                  setCreateShortDesc(e.target.value);
                }}
              />
              <TextField
                label="Long Description"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={createLongDesc}
                onChange={(e) => {
                  setCreateLongDesc(e.target.value);
                }}
              />
              <TextField
                select
                label="Item Type"
                fullWidth
                margin="normal"
                value={createItemType}
                onChange={(e) => {
                  setCreateItemType(e.target.value);
                }}
              >
                <MenuItem value="Product">Product</MenuItem>
                <MenuItem value="Service">Service</MenuItem>
              </TextField>
              <TextField
                select
                label="Varient Exist"
                fullWidth
                margin="normal"
                value={createVarientExist}
                onChange={(e) => {
                  setCreateVarientExist(e.target.value);
                }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              <TextField
                label="Making Cost"
                fullWidth
                margin="normal"
                value={createMakingCost}
                onChange={(e) => {
                  setCreateMakingCost(e.target.value);
                }}
              />
              <TextField
                label="Inventory Cost"
                fullWidth
                margin="normal"
                value={createInvnCost}
                onChange={(e) => {
                  setCreateInvnCost(e.target.value);
                }}
              />
              <TextField
                label="Purchase Price"
                fullWidth
                margin="normal"
                value={createPurchasePrice}
                onChange={(e) => {
                  setCreatePurchasePrice(e.target.value);
                }}
              />
              <TextField
                label="Sale Price"
                fullWidth
                margin="normal"
                value={createSalePrice}
                onChange={(e) => {
                  setCreateSalePrice(e.target.value);
                }}
              />
              <TextField
                label="Discount Percentage"
                fullWidth
                margin="normal"
                value={createDiscountPercentage}
                onChange={(e) => {
                  setCreateDiscountPercentage(e.target.value);
                }}
              />
              <TextField
                label="Purchase Percentage"
                fullWidth
                margin="normal"
                value={createPurchasePercentage}
                onChange={(e) => {
                  setCreatePurchasePercentage(e.target.value);
                }}
              />
              <TextField
                label="Saletax Percentage"
                fullWidth
                margin="normal"
                value={createSaleTaxPercentage}
                onChange={(e) => {
                  setCreateSaleTaxPercentage(e.target.value);
                }}
              />
              <TextField
                select
                label="Status"
                fullWidth
                margin="normal"
                value={createStatus}
                onChange={(e) => {
                  setCreateStatus(e.target.value);
                }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    menuItemsCreate(orgId, categoryId);
                  }}
                >
                  SUBMIT
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {updatePopup && (
        <Modal
          open={updatePopup}
          onClose={() => {
            setUpdatePopup(false);
          }}
          aria-labelledby="edit-hub-modal"
          aria-describedby="edit-hub-form"
        >
          <Paper className={style.createModal} elevation={3}>
            <h2 id="edit-hub-modal">Edit Stock Items</h2>
            <div className={style.modalContent}>
              <TextField
                label="Item Name"
                fullWidth
                margin="normal"
                value={editName}
                onChange={(e) => {
                  setEditName(e.target.value);
                }}
              />
              <TextField
                label="Short Description"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                value={editShortDesc}
                onChange={(e) => {
                  setEditShortDesc(e.target.value);
                }}
              />
              <TextField
                label="Short Description"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={editLongDesc}
                onChange={(e) => {
                  setEditLongDesc(e.target.value);
                }}
              />
              <TextField
                select
                label="Item Type"
                fullWidth
                margin="normal"
                value={editItemType}
                onChange={(e) => {
                  setEditItemType(e.target.value);
                }}
              >
                <MenuItem value="Product">Product</MenuItem>
                <MenuItem value="Service">Service</MenuItem>
              </TextField>
              <TextField
                select
                label="Varient"
                fullWidth
                margin="normal"
                value={editVarient}
                onChange={(e) => {
                  setEditVarient(e.target.value);
                }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              <TextField
                label="Making Cost"
                fullWidth
                margin="normal"
                value={editMakingCost}
                onChange={(e) => {
                  setEditMakingCost(e.target.value);
                }}
              />
              <TextField
                label="Inventory Cost"
                fullWidth
                margin="normal"
                value={editInvenCost}
                onChange={(e) => {
                  setEditInvenCost(e.target.value);
                }}
              />
              <TextField
                label="Purchase Price"
                fullWidth
                margin="normal"
                value={editPurchasePrice}
                onChange={(e) => {
                  setEditPurchasePrice(e.target.value);
                }}
              />
              <TextField
                label="Sale Price"
                fullWidth
                margin="normal"
                value={editSalePrice}
                onChange={(e) => {
                  setEditSalePrice(e.target.value);
                }}
              />
              <TextField
                label="Sale Discount Percentage"
                fullWidth
                margin="normal"
                value={editDiscPercentage}
                onChange={(e) => {
                  setEditDiscPercentage(e.target.value);
                }}
              />
              <TextField
                label="Purchase Tax Percentage"
                fullWidth
                margin="normal"
                value={editPurchasePercentage}
                onChange={(e) => {
                  setEditPurchasePercentage(e.target.value);
                }}
              />
              <TextField
                label="Saletax Percentage"
                fullWidth
                margin="normal"
                value={editSalePercentage}
                onChange={(e) => {
                  setEditSalePercentage(e.target.value);
                }}
              />
              <TextField
                select
                label="Status"
                fullWidth
                margin="normal"
                value={editStatus}
                onChange={(e) => {
                  setEditStatus(e.target.value);
                }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.modalActions}>
                <button
                  className={style.EditSub}
                  variant="contained"
                  onClick={() => {
                    menuItemsUpdate();
                  }}
                >
                  SUBMIT
                </button>
                <button
                  className={style.EditClose}
                  variant="outlined"
                  onClick={() => {
                    setUpdatePopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="action-modal">
              {popupActionType === "delete"
                ? "Confirm Delete"
                : "Confirm Unmap"}
            </h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>
                Are you sure you want to{" "}
                {popupActionType === "delete" ? "delete" : "unmap"} {deleteName}
                ?
              </p>
              <div className={style.modalActions}>
                <button
                  variant="contained"
                  onClick={() => {
                    popupActionType === "delete"
                      ? menuItemDelete()
                      : menuItemUnmap();
                  }}
                  className={style.EditSub}
                >
                  Confirm
                </button>
                <button
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                  className={style.EditClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
}

export default MenuItems;
